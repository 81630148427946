body {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center; }

.error-h1 {
  font-size: 3rem;
  color: #388fc9; }

h1 {
  margin-top: 0;
  margin-bottom: 0;
  color: #388fc9; }

p {
  font-size: 1.1rem;
  margin-top: 7.5px;
  margin-bottom: 30px; }

.row {
  width: 100%; }

img {
  width: 100%; }
